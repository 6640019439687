"server-only";

import { CentraSelectionItem } from "../schemas/selection/selectionProductSchema";
import type { CentraSelection } from "../schemas/selection/selectionSchema";
import {
  CentraProductAllPricesBase,
  CentraProductBasic,
  CentraProductSinglePricesBase,
} from "../types/product";
import { fetchProductsByIdsFromCentra } from "./fetchers/fetchProductsByIds";
import {
  buildBaseAtoms,
  buildClientAtoms,
  buildSelectionAtoms,
  buildSelectionProductAtoms,
  buildServerAtoms,
} from "./formatting/atomics/builders";
import { isFullCentraProduct } from "./utilities";

// TODO: Forgive me type system, for I have sinned with this polyfilled fetch from Next
type Fetch = (arg0: any, ...args: any[]) => Promise<any>;

export const buildProjectAtomics = <
  ProductServer extends CentraProductAllPricesBase,
  ProductClient extends CentraProductSinglePricesBase,
  MediaFields,
  Relation,
  Product extends CentraProductBasic = ProductServer | ProductClient,
  SelectionItem extends CentraSelectionItem = CentraSelectionItem,
  FormattedSelection extends CentraSelection = CentraSelection,
>(
  props?:
    | {
        breadcrumbPrefix?: string;
      }
    | undefined,
) => {
  const getMediaBySize = (product: Product, size: MediaFields) =>
    // Slightly hacky any here, but should in practice be safe, and is a huge pain to fix
    ((product as any).media[size] as string[]).map((url) => ({
      href: url,
      alt: "",
    }));
  const getRelation = (product: Product) => product.relation as Relation;
  const baseAtomics = {
    ...buildBaseAtoms<ProductServer, ProductClient>({
      breadcrumbPrefix: props?.breadcrumbPrefix,
    }),
    getMediaBySize,
    getRelation,
  };

  const clientAtomics = buildClientAtoms<ProductClient>();
  const serverAtomics = buildServerAtoms<ProductServer>();

  const isServerProduct = isFullCentraProduct<ProductServer, ProductClient>;

  const getSelectionItemMedia = (item: SelectionItem, size: MediaFields) =>
    ((item as any)?.product?.media?.[size] as string[])?.map((url) => ({
      href: url,
      alt: `${item?.product?.name}-${item?.product?.variantName}`,
    }));

  const getFirstSelectionImage = (item: SelectionItem, size: MediaFields) => {
    const media = getSelectionItemMedia(item, size);
    return media ? media[0] : undefined;
  };
  const selectionProductAtomics = {
    ...buildSelectionProductAtoms<SelectionItem>(),
    ...buildSelectionAtoms<FormattedSelection>(),
    getSelectionItemMedia: getSelectionItemMedia,
    getFirstImage: getFirstSelectionImage,
  };

  const atomics = {
    baseAtomics,
    clientAtomics,
    serverAtomics,
    isServerProduct,
    selectionProductAtomics,
  };

  return atomics;
};

type Market = number | "all";

// Generic product fetcher, can takes tag, uri, and formatter to create tagged cached fetch
const fetchProductFromCentra = async <pT, pR>(
  uri: string,
  tag: string,
  apiUrl: string,
  apiKey: string,
  fetch: Fetch,
  productFormatter: (arg0: pT) => pR | Promise<pR>,
  market?: Market,
): Promise<pR> => {
  if (!uri) throw new Error("No uri provided");
  const res = await fetch(`${apiUrl}/products`, {
    method: "POST",
    body: JSON.stringify({
      uri: {
        uri,
        for: ["product"],
      },
      market: market,
      pricelist: "all",
      relatedProducts: true,
    }),
    headers: {
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
    },
    next: {
      tags: [`${tag}-${uri}`, tag],
    },
  });

  const data = await res.json();

  const productData = data.products[0];

  if (productData?.bundleInfo) {
    const res = await fetch(`${apiUrl}/bundles/${productData.product}`, {
      method: "POST",

      headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pricelist: "all",
      }),
      next: {
        tags: [`${tag}-${uri}`, tag],
      },
    });
    const bundleData = await res.json();
    productData.bundleData = bundleData;
  }

  const formattedData = productFormatter(productData);

  if (productFormatter.constructor.name === "AsyncFunction") {
    await formattedData;
  }

  return formattedData;
};

const fetchProductByIdFromCentra = async <pT, pR>(
  id: string,
  tag: string,
  apiUrl: string,
  apiKey: string,
  fetch: Fetch,
  productFormatter: (arg0: pT) => pR | Promise<pR>,
  cache: boolean = true,
  market?: Market,
): Promise<pR> => {
  if (!id) throw new Error("No id provided");

  const options = cache
    ? {
        next: {
          tags: [`${tag}-${id}`, "product"],
        },
      }
    : {
        cache: "no-store",
      };
  const fetchParams = {
    method: "POST",
    body: JSON.stringify({
      products: [id],
      pricelist: "all",
      market: market,
      relatedProducts: true,
    }),
    headers: {
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
    },
    ...options,
  };
  const res = await fetch(`${apiUrl}/products`, fetchParams);

  const data = await res.json();

  const productData = data.products[0];

  const formattedData = productFormatter(productData);

  if (productFormatter.constructor.name === "AsyncFunction") {
    await formattedData;
  }

  return formattedData;
};

// TODO:  This could be extended to take an optional object of tags and formatters and then build additional bespoke fetchers
export const buildFetchers = <
  Product extends CentraProductBasic,
  rTPDP = unknown,
  rTPC = unknown,
>(
  apiUrl: string,
  apiKey: string,
  fetch: Fetch,
  pdpFormatter: (product: Product) => rTPDP | Promise<rTPDP>,
  pcFormatter: (product: Product) => rTPC | Promise<rTPC>,
) => {
  const fetchPDP = async (uri: string, market?: Market) =>
    fetchProductFromCentra(
      uri,
      "pdp",
      apiUrl,
      apiKey,
      fetch,
      pdpFormatter,
      market,
    );
  const fetchPC = async (uri: string, market?: Market) =>
    fetchProductFromCentra(
      uri,
      "pc",
      apiUrl,
      apiKey,
      fetch,
      pcFormatter,
      market,
    );

  const fetchPCById = async (id: string, market?: Market) =>
    fetchProductByIdFromCentra(
      id,
      "pc",
      apiUrl,
      apiKey,
      fetch,
      pcFormatter,
      true,
      market,
    );
  const fetchCustomById = async <T>(
    id: string,
    revalKey: string,
    formatter: (product: Product) => T | Promise<T>,
    cache: boolean,
    market?: Market,
  ) =>
    fetchProductByIdFromCentra(
      id,
      revalKey,
      apiUrl,
      apiKey,
      fetch,
      formatter,
      cache,
      market,
    );

  const fetchPCByIds = async ({
    ids,
    market,
  }: {
    ids: string[];
    market?: Market;
  }) => {
    return fetchProductsByIdsFromCentra({
      ids,
      tag: "pc",
      apiKey,
      apiUrl,
      productFormatter: pcFormatter,
      cache: true,
      market,
      fetch,
    });
  };

  return {
    fetchPDP,
    fetchPC,
    fetchPCById,
    fetchCustomById,
    fetchPCByIds,
  };
};

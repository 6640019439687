import { z } from "zod";
import { CentraSelection } from "../../schemas/selection/selectionSchema";

export type UpdateUser = {
  country?: string;
  newEmail?: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  address1?: string;
  city?: string;
  zipCode?: string;
  address2?: string;
};

const updateUser = async <FormattedSelection>(
  variables: UpdateUser,
  customerToken: string,
  selectionSchema: z.ZodSchema,
  api: string,
  formatter: (selection: CentraSelection) => FormattedSelection
) => {
  const res = await fetch(`${api}/customer/update`, {
    headers: {
      "API-token": customerToken || "",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(variables),
    method: "PUT"
  });
  if (!res.ok) throw Error("Error logging in");
  const response = await res.json();
  return formatter(response);
};

export default updateUser;

import type { StateCreator } from "zustand";

import env from "@/env.mjs";

import type { IState } from "../persistedStore";

export interface IEnvironmentState {
  preview: boolean;
  environment: "development" | "production" | "test" | "preview";
  fakeProduction: boolean;
  fakeProductionToggle: () => void;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  isDesktop: boolean;
  setIsDesktop: (isDesktop: boolean) => void;
  hasWindow: boolean;
  setHasWindow: (hasWindow: boolean) => void;
  setPreview: (preview: boolean) => void;
}

const createEnvironmentSlice: StateCreator<
  IState,
  [],
  [],
  IEnvironmentState
> = (set, _get) => ({
  environment: env.NEXT_PUBLIC_ENV as
    | "development"
    | "production"
    | "test"
    | "preview",
  preview: false,
  fakeProduction: false,
  fakeProductionToggle: () =>
    set((state) => ({
      fakeProduction: !state.fakeProduction,
      environment: !state.fakeProduction
        ? "production"
        : (env.NEXT_PUBLIC_ENV as
            | "development"
            | "production"
            | "test"
            | "preview"),
    })),
  isMobile: false,
  setIsMobile: (isMobile) => set(() => ({ isMobile })),
  isDesktop: false,
  setIsDesktop: (isDesktop) => set(() => ({ isDesktop })),
  hasWindow: false,
  setHasWindow: (hasWindow) => set(() => ({ hasWindow })),
  setPreview: (preview) =>
    set(() => ({
      preview,
      environment: preview
        ? "preview"
        : (env.NEXT_PUBLIC_ENV as
            | "development"
            | "production"
            | "test"
            | "preview"),
    })),
});

export default createEnvironmentSlice;

import { buildSelectionFetchers } from "@frend-digital/centra/src/selectionFetchers";

import env from "@/env.mjs";

import type { FormattedSelection } from "./formatters/formatSelection";
import formatSelection from "./formatters/formatSelection";

const apiUrl = env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

export const {
  addToCart,
  changePassword,
  getSelection,
  loginUser,
  logoutUser,
  resetPassword,
  resetPasswordEmail,
  setCartItemQuantity,
  setPromo,
  signUpUser,
  updateUser,
} = buildSelectionFetchers<FormattedSelection>(formatSelection, apiUrl);

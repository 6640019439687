import { z } from "zod";
import { CentraSelection } from "../../schemas/selection/selectionSchema";

const signUpUser = async <FormattedSelection>(
  variables: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    newsletter?: boolean;
  },
  customerToken: string,
  selectionSchema: z.ZodSchema,
  api: string,
  formatter: (selection: CentraSelection) => FormattedSelection
) => {
  const { email, password, lastName, firstName, newsletter } = variables;
  if (newsletter) {
    const res = await fetch(`${api}/newsletter-subscription`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        email
      })
    });
  }

  const res = await fetch(`${api}/register/`, {
    headers: {
      "API-token": customerToken || "",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      password,
      firstName,
      lastName,
      newsletter
    }),
    method: "POST"
  });
  if (!res.ok) {
    const errorResponse = await res.json();
    if (errorResponse?.errors) {
      switch (Object.values(errorResponse.errors)[0]) {
        case "already registered":
          throw Error(
            "You already have an account.  <a href='/reset-password'>Click here to reset your password</a>"
          );
        case "not valid":
          throw Error(
            "Password not valid. Please enter a secure password to protect your account."
          );
      }
    } else {
      throw Error("Error signing up");
    }
  }
  const response = await res.json();
  return formatter(response);
};

export default signUpUser;

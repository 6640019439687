import type { ZodArray, ZodString } from "zod";
import { z } from "zod";

import type { MediaKeys } from "../centra/atomicSetup";

export default function KeysToZodSchema(
  keys: Readonly<MediaKeys[]>,
  _zodType: z.ZodType<any>
) {
  const objectWithKeys = keys.reduce(
    (acc, key) => ({ ...acc, [key]: _zodType }),
    {} as { [key in MediaKeys]: ZodArray<ZodString, "many"> }
  );
  return z.object(objectWithKeys);
}

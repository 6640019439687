import type { StateCreator } from "zustand";

import type { IState } from "../persistedStore";

export interface ISelectionState {
  token: string;
  setToken: (tokenValue: string) => void;
  clearToken: () => void;
  openCart: boolean;
  cartQuantity: number;
  setCartQuantity: (quantity: number) => void;
  toggleCart: () => void;
  setOpenCart: () => void;
  setClosedCart: () => void;
}

const createSelectionSlice: StateCreator<IState, [], [], ISelectionState> = (
  set,
  _get
) => ({
  token: "",
  setToken: (tokenValue: string) => set(() => ({ token: tokenValue })),
  clearToken: () => set(() => ({ token: "" })),
  openCart: false,
  cartQuantity: 0,
  setCartQuantity: (quantity: number) =>
    set((_state) => ({ cartQuantity: quantity })),
  toggleCart: () => set((state) => ({ openCart: !state.openCart })),
  setOpenCart: () => set((_state) => ({ openCart: true })),
  setClosedCart: () => set((_state) => ({ openCart: false })),
});

export default createSelectionSlice;

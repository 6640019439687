import { z } from "zod";
import { CentraSelection } from "../../schemas/selection/selectionSchema";

const resetPassword = async <FormattedSelection>(
  variables: {
    newPassword: string;
    i: any;
    id: any;
  },
  selectionSchema: z.ZodSchema,
  api: string,
  formatter: (selection: CentraSelection) => FormattedSelection
) => {
  const { newPassword, i, id } = variables;
  const res = await fetch(`${api}/password-reset/`, {
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      newPassword,
      i,
      id
    })
  });

  if (!res.ok) {
    const errorResponse = await res.json();
    if (errorResponse?.errors) {
      console.log(errorResponse?.errors);
    } else {
      throw Error("Error reseting password");
    }
  }

  const response = await res.json();
  return formatter(response);
};

export default resetPassword;

import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

const env = createEnv({
  server: {
    FF_KEY: z.string(),
    NEXT_PREVIEW_TOKEN: z.string(),
    VIMEO_API_KEY: z.string(),
    CENTRA_API_SECRET: z.string(),
    CENTRA_INTEGRATION_TOKEN: z.string(),
    CENTRA_INTEGRATION_URL: z.string().url(),
    STORYBLOK_ACCESS_TOKEN: z.string(),
    STORYBLOK_ACCESS_TOKEN_PREVIEW: z.string(),
  },
  client: {
    NEXT_PUBLIC_STORYBLOK_TOKEN: z.string(),
    NEXT_PUBLIC_CENTRA_CHECKOUT_API: z.string(),
    NEXT_PUBLIC_ENV: z.string(),
    NEXT_PUBLIC_API_KEY: z.string(),
  },
  runtimeEnv: {
    FF_KEY: process.env.FF_KEY,
    NEXT_PREVIEW_TOKEN: process.env.NEXT_PREVIEW_TOKEN,
    VIMEO_API_KEY: process.env.VIMEO_API_KEY,
    CENTRA_API_SECRET: process.env.CENTRA_API_SECRET,
    CENTRA_INTEGRATION_TOKEN: process.env.CENTRA_INTEGRATION_TOKEN,
    CENTRA_INTEGRATION_URL: process.env.CENTRA_INTEGRATION_URL,
    STORYBLOK_ACCESS_TOKEN: process.env.STORYBLOK_ACCESS_TOKEN,
    STORYBLOK_ACCESS_TOKEN_PREVIEW: process.env.STORYBLOK_ACCESS_TOKEN_PREVIEW,
    NEXT_PUBLIC_STORYBLOK_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
    NEXT_PUBLIC_CENTRA_CHECKOUT_API:
      process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API,
    NEXT_PUBLIC_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_API_KEY: process.env.NEXT_PUBLIC_API_KEY,
  },
});

export default env;

import { z } from "zod";
import { CentraSelection } from "../../schemas/selection/selectionSchema";

const getSelection = async <FormattedSelection>({
  token,
  setToken,
  selectionSchema,
  api,
  formatter,
  signal
}: {
  token: string;
  setToken: (token: string) => void;
  selectionSchema: z.ZodSchema;
  api: string;
  formatter: (selection: CentraSelection) => FormattedSelection;
  signal?: AbortSignal;
}) => {
  const res = await fetch(`${api}/selection/`, {
    headers: { "API-token": token ?? "", "Content-Type": "application/json" },
    method: "GET",
    cache: "no-store",
    signal
  });

  if (!res.ok) throw Error("Error fetching selection");

  const selection = await res.json();

  setToken(selection.token);

  // TODO: fix selectionSchema parsing
  // const parsedData = selectionSchema.safeParse(selection);
  // if (!parsedData.success)
  //   throw Error(`Error parsing selection data: ${parsedData.error}`);
  // const data = parsedData.data;

  const data = selection;

  return formatter(data);
};

export default getSelection;

import { z } from "zod";
import { CentraSelection } from "../../schemas/selection/selectionSchema";

const setCartItemQuantity = async <FormattedSelection>(
  variables: {
    line: string;
    newQuantity: number;
  },
  customerToken: string,
  selectionSchema: z.ZodSchema,
  api: string,
  formatter: (selection: CentraSelection) => FormattedSelection
) => {
  const res = fetch(
    `${api}/lines/${variables.line}/quantity/${variables.newQuantity}`,
    {
      method: "PUT",
      headers: {
        "API-token": customerToken || "",
        "Content-Type": "application/json"
      }
    }
  )
    .then((res) => {
      if (!res.ok) throw Error("Error updating quantity");
      return res.json();
    })
    .then((data) => {
      if (data === null) throw Error("Error updating quantity");
      return data;
    })
    .then((data) => {
      // TODO: fix selectionSchema parsing
      // const parsedRes = selectionSchema.safeParse(data);
      // if (!parsedRes.success)
      //   throw Error("Error adding product to cart - return type mismatch");
      // return formatter(parsedRes.data);

      const formattedData = formatter(data);

      return formattedData;
    })
    .catch((err) => {
      throw err;
    });

  return res;
};

export default setCartItemQuantity;

import type { StateCreator } from "zustand";

import type { IState } from "../persistedStore";

export interface IWishlistState {
  wishlistIds: string[];
  wishlistRemoved: string[];
  wishlistAddId: (id: string) => void;
  wishlistRemoveId: (id: string) => void;
  wishlistRestoreLastRemoved: () => void;
  wishlistClearIds: () => void;
  wishlistClearRemoved: () => void;
  wishlistClearAll: () => void;
}

const createWishlistSlice: StateCreator<IState, [], [], IWishlistState> = (
  set,
  _get,
) => ({
  wishlistIds: [],
  wishlistRemoved: [],
  wishlistAddId: (id: string) =>
    set((state) => {
      return {
        wishlistIds: state.wishlistIds.includes(id)
          ? state.wishlistIds
          : [id, ...state.wishlistIds],
        //   wishlistRemoved: state.wishlistRemoved.includes(id)
        //     ? state.wishlistRemoved.filter((rId) => rId !== id)
        //     : state.wishlistRemoved,
      };
    }),
  wishlistRemoveId: (id: string) =>
    set((state) => {
      const index = state.wishlistIds.findIndex((wId) => wId === id);

      if (index === -1) return {};
      id = state.wishlistIds[index] || "";

      return {
        // wishlistRemoved: id
        //   ? [id, ...state.wishlistRemoved]
        //   : state.wishlistRemoved,
        wishlistIds:
          state.wishlistIds.length === 1
            ? []
            : state.wishlistIds.filter((wId) => wId !== id),
      };
    }),
  wishlistRestoreLastRemoved: () =>
    set((state) => {
      const lastRemoved = state.wishlistRemoved[0];
      if (!lastRemoved) return {};
      state.wishlistAddId(lastRemoved);
      return {};
    }),
  wishlistClearIds: () =>
    set(() => ({
      wishlistIds: [],
    })),
  wishlistClearRemoved: () =>
    set(() => ({
      wishlistRemoved: [],
    })),
  wishlistClearAll: () =>
    set((state) => {
      state.wishlistClearIds;

      state.wishlistClearRemoved;
      return {};
    }),
});

export default createWishlistSlice;

import { CentraSelection } from "../../schemas/selection/selectionSchema";
import { z } from "zod";

const setPromo = async <FormattedSelection>(
  variables: { code: string; remove: boolean },
  customerToken: string,
  selectionSchema: z.ZodSchema,
  api: string,
  formatter: (selection: CentraSelection) => FormattedSelection
) => {
  const res = fetch(`${api}/vouchers`, {
    method: !variables.remove ? "POST" : "DELETE",
    headers: { "API-token": customerToken || "" },
    body: JSON.stringify({
      voucher: variables.code,
    }),
  })
    .then((res) => {
      if (!res.ok) throw Error("Invalid promo code");
      return res.json();
    })
    .then((data) => {
      if (data === null || Object.prototype.hasOwnProperty.call(data, "errors"))
        throw Error("Invalid promo code");
      return data;
    })
    .then((data) => {
      const parsedRes = selectionSchema.safeParse(data);
      if (!parsedRes.success)
        throw Error("Error adding product to cart - return type mismatch");
      return formatter(parsedRes.data);
    })
    .catch((err) => {
      throw err;
    });

  return res;
};

export default setPromo;

import { z } from "zod";
import { CentraSelection } from "../../schemas/selection/selectionSchema";

const loginUser = async <FormattedSelection>(
  variables: { email: string; password: string },
  customerToken: string,
  selectionSchema: z.ZodSchema,
  api: string,
  formatter: (selection: CentraSelection) => FormattedSelection
) => {
  const { email, password } = variables;
  const res = await fetch(`${api}/login/${email}`, {
    headers: {
      "API-token": customerToken || "",
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({ password: password })
  });
  if (!res.ok) {
    const errorResponse = await res.json();
    if (errorResponse?.errors) {
      if (errorResponse.errors.password || errorResponse.errors.email) {
        throw Error("Incorrect email or password");
      } else {
        console.log(errorResponse?.errors);
      }
    } else {
      throw Error("Error signing up");
    }
  }
  const response = await res.json();
  return formatter(response);
};

export default loginUser;

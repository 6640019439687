import {
  CentraProductAllPricesBase,
  CentraProductBasic,
  CentraProductItemBasic,
  CentraProductItemFull,
  CentraProductSinglePricesBase,
} from "../../../types/product";
import { atomicGetField } from "./builders";

export const buildGetProductBreadcrumbs =
  (prefix: string = "products") =>
  <P extends CentraProductAllPricesBase | CentraProductSinglePricesBase>(
    product: P,
  ): { name: string; uri: string }[] => {
    if (!product.categoryUri || !product.categoryName) {
      throw new Error("category uri or names missing");
    }
    try {
      const fullCategoryUri = product?.categoryUri;
      const categoryUris = fullCategoryUri?.split("/");
      const categoryNames = product.categoryName;

      if (categoryNames?.length !== categoryUris?.length) {
        throw new Error("Mismatch in length of category names and sub paths");
      }

      const breadcrumbs =
        categoryUris?.map((_, i, arr) => ({
          //@poulDanielsen - we're getting a type error here, because the type of _ is any at build-time. What is the correct way to fix this?
          name: (categoryNames && categoryNames[i]) || "",
          uri: `/${prefix}/` + arr.slice(0, i + 1).join("/"),
        })) || [];
      const productUri = atomicGetField("uri", product);
      breadcrumbs?.push({ name: product.name, uri: productUri });
      return breadcrumbs;
    } catch (e: any) {
      throw new Error(`Could not create breadcrumbs: ${e.message}`);
    }
  };

export const getCanonicalCategory = <P extends CentraProductBasic>(
  product: P,
): string => {
  if (!product.categoryName) {
    throw new Error("category name missing");
  }
  try {
    const canonical =
      (product?.categoryName &&
        product?.categoryName?.length > 0 &&
        product?.categoryName[0]) ||
      "";
    if (!canonical) throw new Error("No canonical category found");
    return canonical;
  } catch (e: any) {
    throw new Error(`Could not find the canonical category: ${e.message}`);
  }
};

export const getCountryOfOrigin = <P extends CentraProductBasic>(
  product: P,
  lang: string,
): string | undefined => {
  const countryCode = product.countryOrigin;
  const countryNames = new Intl.DisplayNames([lang], { type: "region" });
  return countryNames.of(countryCode);
};

function isServerSize(
  item: CentraProductItemFull | CentraProductItemBasic,
): item is CentraProductItemFull {
  return (item as CentraProductItemFull).warehouses != undefined;
}
export const getProductSizes = <
  P extends CentraProductAllPricesBase | CentraProductSinglePricesBase,
>(
  product: P,
):
  | {
      name: string;
      label: string;
      stock: boolean;
      itemId: string;
      fewLeft: boolean;
    }[]
  | undefined => {
  const sizeLabels = product.tableMappings?.Label.x;
  const sizes = product.items?.map((item, i) => {
    const isFull = isServerSize(item);
    try {
      return {
        name: item.name,
        label: sizeLabels?.[item.name] || item.name,
        stock: item.stock !== "no",
        itemId: item.item,
        fewLeft: item.stock === "few",
        warehouses: isFull ? item.warehouses : undefined,
      };
    } catch (e) {
      throw new Error(
        `Problem formatting size at index: ${i}, with name ${item.name}`,
      );
    }
  });
  return sizes;
};

export const getSizeRange = <P extends CentraProductBasic>(
  product: P,
): string => {
  const sizes = product.itemTable?.original?.x;
  const first = sizes?.[0];
  const last = sizes?.[sizes.length - 1];
  if (first && first === last) return first;
  return `${first} - ${last}`;
};

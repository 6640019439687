import { CentraSelection } from "../../schemas/selection/selectionSchema";
import { z } from "zod";

const logoutUser = async <FormattedSelection>(
  customerToken: string,
  selectionSchema: z.ZodSchema,
  api: string,
  formatter: (selection: CentraSelection) => FormattedSelection
) => {
  const res = await fetch(`${api}/logout/`, {
    headers: {
      "API-token": customerToken || "",
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  if (!res.ok) throw Error("Error logging out");
  const response = await res.json();
  const parsedData = selectionSchema.safeParse(response);
  if (!parsedData.success) throw Error("Error parsing selection data");
  const data = parsedData.data;
  return formatter(data);
};

export default logoutUser;

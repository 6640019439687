import { buildProjectAtomics } from "@frend-digital/centra/src/productFetchers";
import type {
  BaseCentraProduct,
  CentraBaseRelation,
  CentraMedia,
  CentraProductAllPrices,
  CentraProductSinglePrices
} from "@frend-digital/centra/types/product";
import { z } from "zod";

import KeysToZodSchema from "../utils/keysToZodSchema";

// It is crucial that these are cast as read only, otherwise the types will not resolve correctly
export const mediaKeys = ["full", "standard", "large-2560"] as const;
const customRelations = ["Look"] as const;
export type MediaKeys = (typeof mediaKeys)[number];

// custom project-specific attributes for the zod selection schema.
export const customSelectionSchema = {
  media: KeysToZodSchema(mediaKeys, z.array(z.string())).partial(),
  product: z
    .object({
      pr_color: z.object({ name: z.string(), code: z.string() }),
      pr_composition_value: z.string(),
      pr_fit_advice: z.string(),
      pr_garment_care: z.string()
    })
    .partial()
};
export type customMediaSchema = z.infer<typeof customSelectionSchema.media>;
export type customProductSchema = z.infer<typeof customSelectionSchema.product>;

type Relation = (typeof customRelations)[number] | CentraBaseRelation;

// TODO: build/place actual type here
type CustomProjectAttributes = {
  media: CentraMedia<MediaKeys>;
  relation: Relation;
  pr_color: {
    name: string;
    code: string;
  };
  pr_composition_value: string;
  pr_fit_advice: string;
  pr_garment_care: string;
  pr_sustainability: string;
  pr_video_value: string;
  pr_look_value: string;
  pr_coming_soon_value: string;
  available: boolean;
};
export type CentraProduct = BaseCentraProduct<CustomProjectAttributes>;
export type CentraProductServer =
  CentraProductAllPrices<CustomProjectAttributes>;
export type CentraProductClient =
  CentraProductSinglePrices<CustomProjectAttributes>;

export const {
  baseAtomics,
  clientAtomics,
  serverAtomics,
  isServerProduct,
  selectionProductAtomics
} = buildProjectAtomics<
  CentraProductServer,
  CentraProductClient,
  MediaKeys,
  Relation
>();

// very simple dummy that just returns the data directly, replace with actual formatters.
// note the typing here, in that it must take one argument, which is the centra product with project specific attributes passed as a generic

// This can also be async, in case subsequent calls need to be made after fetching product data.
// This is deliberately built to prevent easily smashing a PC call inside the PDP call, build a separate function for that, and Promise.all() the PCs

// Note that there are no multiple fetchers built, in order to have atomic cache for each individual PC & PDP

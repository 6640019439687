import { z } from "zod";
import { CentraSelection } from "../../schemas/selection/selectionSchema";

const resetPasswordEmail = async <FormattedSelection>(
  variables: {
    email: string;
    linkUri: string;
  },
  selectionSchema: z.ZodSchema,
  api: string,
  formatter: (selection: CentraSelection) => FormattedSelection
) => {
  const { email, linkUri } = variables;
  const res = await fetch(`${api}/password-reset-email/${email}`, {
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      linkUri
    })
  });

  if (!res.ok) {
    const errorResponse = await res.json();
    console.log(errorResponse);
    throw Error("Email not registred");
  }
  const response = await res.json();
  return formatter(response);
};

export default resetPasswordEmail;

import { z } from "zod";

// TODO: clean this up and implement it in the new selection schema
export const selectionSchema = z
  .object({
    token: z.string(),
    selection: z
      .object({
        language: z.union([z.string(), z.null()]),
        currency: z.string(),
        paymentMethod: z.string(),
        paymentMethodName: z.string(),
        shippingMethod: z.string(),
        shippingMethodName: z.string(),
        pluginFields: z.object({
          shipwallet: z.object({
            version: z.number(),
            snippet: z.string(),
            session_id: z.string(),
            ingridAttributes: z.null(),
            deliveryOptionsAvailable: z.boolean()
          })
        }),
        centraCheckoutScript: z.string(),
        items: z.array(
          z.union([
            z
              .object({
                item: z.string(),
                productUrl: z.null(),
                category: z.null(),
                size: z.string(),
                sku: z.string(),
                ean: z.string(),
                quantity: z.number(),
                comment: z.string().nullable(),
                localizedSize: z.null(),
                line: z.string(),
                priceEach: z.string(),
                priceEachAsNumber: z.number(),
                totalPrice: z.string(),
                totalPriceAsNumber: z.number(),
                priceEachBeforeDiscount: z.string(),
                priceEachBeforeDiscountAsNumber: z.number(),
                anyDiscount: z.boolean(),
                taxPercent: z.number(),
                priceEachWithoutTax: z.string(),
                priceEachWithoutTaxAsNumber: z.number(),
                priceEachReduction: z.string(),
                priceEachReductionAsNumber: z.number(),
                totalPriceBeforeDiscount: z.string(),
                totalPriceBeforeDiscountAsNumber: z.number(),
                product: z.object({
                  product: z.string(),
                  name: z.string(),
                  uri: z.string(),
                  sku: z.string(),
                  productSku: z.string(),
                  brand: z.string(),
                  brandName: z.string(),
                  brandUri: z.string(),
                  collection: z.string(),
                  collectionName: z.string().nullable(),
                  collectionUri: z.string(),
                  variantName: z.string(),
                  countryOrigin: z.string().nullable(),
                  excerpt: z.string(),
                  excerptHtml: z.string(),
                  description: z.string(),
                  descriptionHtml: z.string(),
                  metaTitle: z.string(),
                  metaDescription: z.string(),
                  metaKeywords: z.string(),
                  stockUnit: z.string(),
                  category: z.string(),
                  centraProduct: z.string(),
                  centraVariant: z.string(),
                  itemQuantityMinimum: z.number(),
                  itemQuantityMultipleOf: z.number(),
                  price: z.string(),
                  priceAsNumber: z.number(),
                  priceBeforeDiscount: z.string(),
                  priceBeforeDiscountAsNumber: z.number(),
                  discountPercent: z.number(),
                  lowestPrice: z.array(
                    z.object({
                      periodDays: z.number(),
                      price: z.string(),
                      priceAsNumber: z.number(),
                      priceBeforeDiscount: z.string(),
                      priceBeforeDiscountAsNumber: z.number()
                    })
                  ),
                  showAsOnSale: z.boolean(),
                  showAsNew: z.boolean(),
                  itemTable: z.object({
                    unit: z.string(),
                    original: z.object({ x: z.array(z.string()) }),
                    x: z.array(z.string()),
                    dividerSymbol: z.string(),
                    desc: z.string(),
                    y: z.array(z.unknown())
                  }),
                  items: z.array(
                    z.object({
                      sizeId: z.string(),
                      item: z.string(),
                      ean: z.string(),
                      itemTableY: z.number(),
                      itemTableX: z.number(),
                      name: z.string(),
                      sku: z.string()
                    })
                  ),
                  categoryName: z.union([z.array(z.string()), z.null()]),
                  categoryUri: z.union([z.string(), z.null()]),
                  categories: z.array(
                    z.object({
                      sortOrder: z.number(),
                      name: z.array(z.string()),
                      category: z.string(),
                      uri: z.string()
                    })
                  ),
                  media: z.object({
                    standard: z.array(z.string()),
                    full: z.array(z.string())
                  }),
                  mediaObjects: z.array(
                    z.object({
                      media: z.number(),
                      sources: z.object({
                        standard: z.array(z.object({ url: z.string() })),
                        full: z.array(z.object({ url: z.string() }))
                      }),
                      attributes: z.array(z.unknown())
                    })
                  ),
                  modifiedAt: z.string(),
                  createdAt: z.string(),
                  preview: z.boolean(),
                  measurementChart: z.union([
                    z.object({
                      unit: z.string(),
                      contents: z.array(
                        z.object({
                          x: z.number(),
                          y: z.number(),
                          content: z.string()
                        })
                      ),
                      x: z.array(z.string()),
                      y: z.array(z.string())
                    }),
                    z.array(z.unknown())
                  ]),
                  pr_color: z.object({
                    name: z.string(),
                    code: z.string()
                  }),
                  pr_composition_value: z.string(),
                  pr_fit_advice: z.string(),
                  pr_garment_care: z.string(),
                  pr_sustainability: z.string(),
                  pr_video_value: z.string(),
                  relation: z.string()
                })
              })
              .deepPartial(),
            z
              .object({
                item: z.string(),
                productUrl: z.null(),
                category: z.null(),
                size: z.string(),
                sku: z.string(),
                ean: z.string(),
                quantity: z.number(),
                comment: z.string().nullable(),
                localizedSize: z.null(),
                line: z.string(),
                priceEach: z.string(),
                priceEachAsNumber: z.number(),
                totalPrice: z.string(),
                totalPriceAsNumber: z.number(),
                priceEachBeforeDiscount: z.string(),
                priceEachBeforeDiscountAsNumber: z.number(),
                anyDiscount: z.boolean(),
                taxPercent: z.number(),
                priceEachWithoutTax: z.string(),
                priceEachWithoutTaxAsNumber: z.number(),
                priceEachReduction: z.string(),
                priceEachReductionAsNumber: z.number(),
                totalPriceBeforeDiscount: z.string(),
                totalPriceBeforeDiscountAsNumber: z.number(),
                product: z
                  .object({
                    product: z.string(),
                    name: z.string(),
                    uri: z.string(),
                    sku: z.string(),
                    productSku: z.string(),
                    brand: z.string(),
                    brandName: z.string(),
                    brandUri: z.string(),
                    collection: z.string(),
                    collectionName: z.string().nullable(),
                    collectionUri: z.string(),
                    variantName: z.string(),
                    countryOrigin: z.string().nullable(),
                    excerpt: z.string(),
                    excerptHtml: z.string(),
                    description: z.string(),
                    descriptionHtml: z.string(),
                    metaTitle: z.string(),
                    metaDescription: z.string(),
                    metaKeywords: z.string(),
                    stockUnit: z.string(),
                    category: z.string(),
                    centraProduct: z.string(),
                    centraVariant: z.string(),
                    itemQuantityMinimum: z.number(),
                    itemQuantityMultipleOf: z.number(),
                    price: z.string(),
                    priceAsNumber: z.number(),
                    priceBeforeDiscount: z.string(),
                    priceBeforeDiscountAsNumber: z.number(),
                    discountPercent: z.number(),
                    lowestPrice: z.array(
                      z.object({
                        periodDays: z.number(),
                        price: z.string(),
                        priceAsNumber: z.number(),
                        priceBeforeDiscount: z.string(),
                        priceBeforeDiscountAsNumber: z.number()
                      })
                    ),
                    showAsOnSale: z.boolean(),
                    showAsNew: z.boolean(),
                    itemTable: z.object({
                      unit: z.string(),
                      original: z.object({ x: z.array(z.string()) }),
                      x: z.array(z.string()),
                      dividerSymbol: z.string(),
                      desc: z.string(),
                      y: z.array(z.unknown())
                    }),
                    items: z.array(
                      z.object({
                        sizeId: z.string(),
                        item: z.string(),
                        ean: z.string(),
                        itemTableY: z.number(),
                        itemTableX: z.number(),
                        name: z.string(),
                        sku: z.string()
                      })
                    ),
                    categoryName: z.union([z.array(z.string()), z.null()]),
                    categoryUri: z.union([z.string(), z.null()]),
                    categories: z.array(
                      z.object({
                        sortOrder: z.number(),
                        name: z.array(z.string()),
                        category: z.string(),
                        uri: z.string()
                      })
                    ),
                    media: z.object({
                      standard: z.array(z.string()),
                      full: z.array(z.string())
                    }),
                    mediaObjects: z.array(
                      z.object({
                        media: z.number(),
                        sources: z.object({
                          standard: z.array(z.object({ url: z.string() })),
                          full: z.array(z.object({ url: z.string() }))
                        }),
                        attributes: z.array(z.unknown())
                      })
                    ),
                    modifiedAt: z.string(),
                    createdAt: z.string(),
                    preview: z.boolean(),
                    measurementChart: z.union([
                      z.object({
                        unit: z.string(),
                        contents: z.array(
                          z.object({
                            x: z.number(),
                            y: z.number(),
                            content: z.string()
                          })
                        ),
                        x: z.array(z.string()),
                        y: z.array(z.string())
                      }),
                      z.array(z.unknown())
                    ]),
                    pr_color: z.object({ name: z.string(), code: z.string() }),
                    pr_composition_value: z.string(),
                    pr_fit_advice: z.string(),
                    pr_garment_care: z.string(),
                    pr_sustainability: z.string(),
                    relation: z.string()
                  })
                  .deepPartial()
              })
              .deepPartial(),
            z
              .object({
                item: z.string(),
                productUrl: z.null(),
                category: z.null(),
                size: z.string(),
                sku: z.string(),
                ean: z.string(),
                quantity: z.number(),
                comment: z.string().nullable(),
                localizedSize: z.null(),
                line: z.string(),
                priceEach: z.string(),
                priceEachAsNumber: z.number(),
                totalPrice: z.string(),
                totalPriceAsNumber: z.number(),
                priceEachBeforeDiscount: z.string(),
                priceEachBeforeDiscountAsNumber: z.number(),
                anyDiscount: z.boolean(),
                taxPercent: z.number(),
                priceEachWithoutTax: z.string(),
                priceEachWithoutTaxAsNumber: z.number(),
                priceEachReduction: z.string(),
                priceEachReductionAsNumber: z.number(),
                totalPriceBeforeDiscount: z.string(),
                totalPriceBeforeDiscountAsNumber: z.number(),
                product: z.object({
                  product: z.string(),
                  name: z.string(),
                  uri: z.string(),
                  sku: z.string(),
                  productSku: z.string(),
                  brand: z.string(),
                  brandName: z.string(),
                  brandUri: z.string(),
                  collection: z.string(),
                  // Workaround for not passing schema on user login (for account alexander@frend.no)
                  collectionName: z.string().nullable(),
                  collectionUri: z.string(),
                  variantName: z.string(),
                  countryOrigin: z.string().nullable(),
                  excerpt: z.string(),
                  excerptHtml: z.string(),
                  description: z.string(),
                  descriptionHtml: z.string(),
                  metaTitle: z.string(),
                  metaDescription: z.string(),
                  metaKeywords: z.string(),
                  stockUnit: z.string(),
                  category: z.string(),
                  centraProduct: z.string(),
                  centraVariant: z.string(),
                  itemQuantityMinimum: z.number(),
                  itemQuantityMultipleOf: z.number(),
                  price: z.string(),
                  priceAsNumber: z.number(),
                  priceBeforeDiscount: z.string(),
                  priceBeforeDiscountAsNumber: z.number(),
                  discountPercent: z.number(),
                  lowestPrice: z.array(
                    z.object({
                      periodDays: z.number(),
                      price: z.string(),
                      priceAsNumber: z.number(),
                      priceBeforeDiscount: z.string(),
                      priceBeforeDiscountAsNumber: z.number()
                    })
                  ),
                  showAsOnSale: z.boolean(),
                  showAsNew: z.boolean(),
                  itemTable: z.object({
                    unit: z.string(),
                    original: z.object({ x: z.array(z.string()) }),
                    x: z.array(z.string()),
                    dividerSymbol: z.string(),
                    desc: z.string(),
                    y: z.array(z.unknown())
                  }),
                  items: z.array(
                    z.object({
                      sizeId: z.string(),
                      item: z.string(),
                      ean: z.string(),
                      itemTableY: z.number(),
                      itemTableX: z.number(),
                      name: z.string(),
                      sku: z.string()
                    })
                  ),
                  categoryName: z.union([z.array(z.string()), z.null()]),
                  categoryUri: z.union([z.string(), z.null()]),
                  categories: z.array(
                    z.object({
                      sortOrder: z.number(),
                      name: z.array(z.string()),
                      category: z.string(),
                      uri: z.string()
                    })
                  ),
                  media: z.object({
                    standard: z.array(z.string()),
                    full: z.array(z.string())
                  }),
                  mediaObjects: z.array(
                    z.object({
                      media: z.number(),
                      sources: z.object({
                        standard: z.array(z.object({ url: z.string() })),
                        full: z.array(z.object({ url: z.string() }))
                      }),
                      attributes: z.array(z.unknown())
                    })
                  ),
                  modifiedAt: z.string(),
                  createdAt: z.string(),
                  preview: z.boolean(),
                  measurementChart: z.union([
                    z.object({
                      unit: z.string(),
                      contents: z.array(
                        z.object({
                          x: z.number(),
                          y: z.number(),
                          content: z.string()
                        })
                      ),
                      x: z.array(z.string()),
                      y: z.array(z.string())
                    }),
                    z.array(z.unknown())
                  ]),
                  pr_color: z.object({ name: z.string(), code: z.string() }),
                  pr_composition_value: z.string(),
                  pr_fit_advice: z.string(),
                  pr_garment_care: z.string(),
                  pr_sustainability: z.string(),
                  pr_video_value: z.string(),
                  relation: z.string()
                })
              })
              .deepPartial()
          ])
        ),
        discounts: z.object({
          anyDiscount: z.boolean(),
          discount: z.string(),
          discountAsNumber: z.number(),
          vouchers: z.array(z.unknown()),
          automaticDiscounts: z.array(z.unknown())
        }),
        totals: z.object({
          itemsTotalPrice: z.string(),
          itemsTotalPriceAsNumber: z.number(),
          totalDiscountPrice: z.union([z.string(), z.number(), z.boolean()]),
          totalDiscountPriceAsNumber: z.union([
            z.string(),
            z.number(),
            z.boolean()
          ]),
          shippingPrice: z.string(),
          shippingPriceAsNumber: z.number(),
          handlingCostPrice: z.string(),
          handlingCostPriceAsNumber: z.number(),
          totalQuantity: z.number(),
          taxDeducted: z.boolean(),
          taxDeductedAsNumber: z.boolean(),
          taxAdded: z.boolean(),
          taxAddedAsNumber: z.boolean(),
          taxPercent: z.number(),
          grandTotalPrice: z.string(),
          grandTotalPriceAsNumber: z.number(),
          grandTotalPriceTax: z.string(),
          grandTotalPriceTaxAsNumber: z.number()
        }),
        vatExempt: z.boolean(),
        address: z.object({
          firstName: z.string(),
          lastName: z.string(),
          email: z.string(),
          phoneNumber: z.string(),
          company: z.string(),
          address1: z.string(),
          address2: z.string(),
          zipCode: z.string(),
          city: z.string(),
          state: z.string(),
          country: z.string(),
          vatNumber: z.string()
        }),
        shippingAddress: z.object({
          firstName: z.string(),
          lastName: z.string(),
          email: z.string(),
          phoneNumber: z.string(),
          company: z.string(),
          address1: z.string(),
          address2: z.string(),
          zipCode: z.string(),
          city: z.string(),
          state: z.string(),
          country: z.string()
        }),
        additionalNotes: z.string(),
        currencyFormat: z.object({
          currency: z.string(),
          name: z.string(),
          prefix: z.string(),
          suffix: z.string(),
          decimalPoint: z.string(),
          decimalDigits: z.string(),
          thousandsSeparator: z.string(),
          denominator: z.number(),
          uri: z.string()
        })
      })
      .deepPartial(),
    paymentMethods: z.array(
      z.object({
        paymentMethod: z.string(),
        name: z.string(),
        paymentMethodType: z.string(),
        supportsInitiateOnly: z.boolean(),
        supportsRecurring: z.boolean(),
        providesCustomerAddressAfterPayment: z.boolean(),
        handlingCost: z.string(),
        handlingCostAsNumber: z.number()
      })
    ),
    paymentFields: z
      .object({
        termsAndConditions: z.object({
          type: z.string(),
          required: z.boolean(),
          visible: z.boolean()
        }),
        address: z.object({
          email: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          company: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          firstName: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          lastName: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          address1: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          address2: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          zipCode: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          city: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          state: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          country: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          phoneNumber: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          identityNumber: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          vatNumber: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          houseNumber: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          houseExtension: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          newsletter: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          })
        }),
        shippingAddress: z.object({
          email: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          company: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          firstName: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          lastName: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          address1: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          address2: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          zipCode: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          city: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          state: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          country: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          phoneNumber: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          houseNumber: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          }),
          houseExtension: z.object({
            type: z.string(),
            required: z.boolean(),
            visible: z.boolean()
          })
        })
      })
      .deepPartial(),
    shippingMethods: z.array(
      z.object({
        shippingMethod: z.string(),
        name: z.string(),
        price: z.string(),
        priceAsNumber: z.number()
      })
    ),
    countries: z.array(
      z.object({
        country: z.string(),
        name: z.string(),
        eu: z.boolean(),
        language: z.string(),
        states: z.union([
          z.array(z.object({ state: z.string(), name: z.string() })),
          z.null()
        ]),
        currency: z.string()
      })
    ),
    languages: z.array(
      z.object({ language: z.string(), name: z.string(), default: z.boolean() })
    ),
    location: z.object({
      country: z.string(),
      name: z.string(),
      state: z.null(),
      stateName: z.string(),
      eu: z.boolean(),
      shipTo: z.boolean(),
      market: z.number(),
      pricelist: z.number(),
      language: z.object({
        language: z.string(),
        name: z.string(),
        default: z.boolean()
      })
    })
  })
  .deepPartial();

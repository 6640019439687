import { z } from "zod";
import { CentraSelection } from "../../schemas/selection/selectionSchema";

export type addToCartVars<FormattedSelection> = {
  variables: { itemId: string; quantity?: number };
  customerToken: string;
  selectionSchema: z.ZodSchema;
  api: string;
  formatter: (selection: CentraSelection) => FormattedSelection;
};

const addToCart = async <FormattedSelection>({
  variables,
  customerToken,
  api,
  formatter,
}: addToCartVars<FormattedSelection>) => {
  const body = variables.quantity
    ? JSON.stringify({ quantity: variables.quantity })
    : undefined;
  const res = await fetch(`${api}/items/${variables.itemId}`, {
    headers: {
      "API-token": customerToken || "",
      "Content-Type": "application/json",
    },
    method: "POST",
    body,
  });
  if (!res.ok) throw Error("Error adding to cart - " + res.statusText);
  const response = await res.json();

  return formatter(response);
};

export default addToCart;

import { selectionSchema as newSelectionSchema } from "../schemas/newSelection/selectionSchema";
import { CentraSelection } from "../schemas/selection/selectionSchema";
import {
  addToCart,
  changePassword,
  getSelection,
  loginUser,
  logoutUser,
  resetPassword,
  resetPasswordEmail,
  setCartItemQuantity,
  setPromo,
  signUpUser,
  updateUser
} from "./selection";
import { UpdateUser } from "./selection/updateUser";

interface IAddToCartVariables {
  itemId: string;
  comment?: string;
}

export const buildSelectionFetchers = <FormattedSelection>(
  formatter: (arg0: CentraSelection) => FormattedSelection,
  api: string
) => {
  // TODO: this works fine, but is not so clean - update this to follow the same pattern as before adding the newSelectionSchema.
  const selectionSchema = newSelectionSchema;
  // selectionSchemaBuilder(selectionSchemaExtension);
  type FinalSchema = typeof selectionSchema;

  const toExport = {
    addToCart: (variables: IAddToCartVariables, customerToken: string) =>
      addToCart<FormattedSelection>({
        variables,
        customerToken,
        selectionSchema,
        api,
        formatter
      }),
    changePassword: (
      variables: {
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
      },
      customerToken: string
    ) =>
      changePassword(variables, customerToken, selectionSchema, api, formatter),
    getSelection: (
      token: string,
      setToken: (token: string) => void,
      signal?: AbortSignal
    ) =>
      getSelection({
        token,
        setToken,
        selectionSchema,
        api,
        formatter,
        signal
      }),
    loginUser: (
      variables: { email: string; password: string },
      customerToken: string
    ) => loginUser(variables, customerToken, selectionSchema, api, formatter),
    logoutUser: (customerToken: string) =>
      logoutUser(customerToken, selectionSchema, api, formatter),
    resetPassword: (variables: { newPassword: string; i: any; id: any }) =>
      resetPassword(variables, selectionSchema, api, formatter),
    resetPasswordEmail: (variables: { email: string; linkUri: string }) =>
      resetPasswordEmail(variables, selectionSchema, api, formatter),
    setCartItemQuantity: (
      variables: { line: string; newQuantity: number },
      customerToken: string
    ) =>
      setCartItemQuantity(
        variables,
        customerToken,
        selectionSchema,
        api,
        formatter
      ),
    setPromo: (
      variables: { code: string; remove: boolean },
      customerToken: string
    ) => setPromo(variables, customerToken, selectionSchema, api, formatter),
    signUpUser: (
      variables: {
        email: string;
        password: string;
        firstName: string;
        lastName: string;
        newsletter?: boolean;
      },
      customerToken: string
    ) => signUpUser(variables, customerToken, selectionSchema, api, formatter),
    updateUser: (variables: UpdateUser, customerToken: string) =>
      updateUser(variables, customerToken, selectionSchema, api, formatter)
  };

  return toExport;
};

// @ts-nocheck
import type { CentraSelectionItem } from "@frend-digital/centra/schemas/selection/selectionProductSchema";
import type { CentraSelection } from "@frend-digital/centra/schemas/selection/selectionSchema";

import { ICartItem } from "@/lib/hooks/useSelection/types";
import {
  generateCartSummary,
  getFirstImage,
  getSelectionItemCategory,
  getSelectionItemId,
  getSelectionItemLine,
  getSelectionItemName,
  getSelectionItemPriceEach,
  getSelectionItemPriceEachAsNumber,
  getSelectionItemPriceEachBeforeDiscount,
  getSelectionItemQuantity,
  getSelectionItemSize,
  getSelectionItemSku,
  getSelectionItemUri,
  getSelectionItemVariantName,
} from "../selectionAtoms";
import { Giftbox, GroupedProducts } from "./types";

const generateCartItem = (item: CentraSelectionItem) => {
  const name = getSelectionItemName(item) ?? "";
  const uri = getSelectionItemUri(item) ?? "";
  const variant = getSelectionItemVariantName(item) ?? "";
  const sku = getSelectionItemSku(item) ?? "";
  const size = getSelectionItemSize(item) ?? "";
  const id = getSelectionItemLine(item) ?? "";
  const itemId = getSelectionItemId(item) ?? "";
  const priceAsNumber = getSelectionItemPriceEachAsNumber(item);
  const price = getSelectionItemPriceEach(item);
  const discountedPrice = getSelectionItemPriceEachBeforeDiscount(item);
  const quantity = getSelectionItemQuantity(item) ?? 0;
  const category = getSelectionItemCategory(item) ?? "";
  const hasGiftbox = item?.product?.pr_gift_box ?? "";
  const media = {
    standard: getFirstImage(item, "standard"),
    full: getFirstImage(item, "full"),
  };
  const line = item.line ?? "";

  return {
    product: item?.product?.product,
    name,
    uri,
    variant,
    size,
    id,
    price,
    quantity,
    media,
    hasGiftbox,
    priceAsNumber,
    discountedPrice,
    itemId,
    line,
    sku,
    category,
  };
};

const extractGiftboxsFromProducts = (items: CentraSelectionItem[]) =>
  items.reduce<GroupedProducts>(
    (acc, item) => {
      if (!item) return acc;

      if (
        getSelectionItemId(item) === "1545-5788" ||
        getSelectionItemId(item) === "1544-5789"
      ) {
        const comment = JSON.parse(item.comment);
        const giftboxId = item.line;

        const giftbox: Giftbox = {
          item: comment?.comment?.item || "",
          relatedItem: comment?.comment?.relatedItem || "",
          txt: comment?.comment?.txt || "",
          giftboxId: giftboxId || "",
          price: item.priceEachAsNumber,
        };

        return { ...acc, giftboxs: [...acc.giftboxs, giftbox] };
      } else {
        return { ...acc, products: [...acc.products, generateCartItem(item)] };
      }
    },
    { giftboxs: [], products: [] },
  );

const getProductsGroupedByGiftbox = (
  products: ICartItem[],
  giftboxs: Giftbox[],
) =>
  products.reduce<ICartItem[]>((acc, cartItem) => {
    const matchingGiftboxs = giftboxs.filter(
      ({ relatedItem }) => relatedItem === cartItem.itemId,
    );

    const numberOfMatchingGiftboxs = matchingGiftboxs.length;
    const quantity = cartItem?.quantity || 1;

    const quantityWithoutGiftbox = quantity - numberOfMatchingGiftboxs;

    const productsWithoutGiftboxs =
      quantityWithoutGiftbox > 0
        ? [
            {
              ...cartItem,
              quantity: quantityWithoutGiftbox,
              totalProductQuantity: quantity,
            },
          ]
        : [];

    const separatedGiftboxs = matchingGiftboxs.reduce<ICartItem[]>(
      (acc, giftbox) => {
        return [
          ...acc,
          {
            ...cartItem,
            giftbox: giftbox,
            quantity: 1,
            totalProductQuantity: quantity,
          },
        ];
      },
      [],
    );

    return [...acc, ...productsWithoutGiftboxs, ...separatedGiftboxs];
  }, []);

export const formatItems = (items: CentraSelectionItem[]) => {
  const { giftboxs, products } = extractGiftboxsFromProducts(items);
  const productsGroupedByGiftbox = getProductsGroupedByGiftbox(
    products,
    giftboxs,
  );

  return productsGroupedByGiftbox;
};
export const formatSelection = (data: CentraSelection) => {
  const items = data.selection?.items ?? [];

  const { giftboxs, products } = extractGiftboxsFromProducts(items);
  const productsGroupedByGiftbox = formatItems(items);

  const generatedSummary = generateCartSummary(data);

  const summary = {
    ...generatedSummary,
    totalQuantity: data.selection?.totals?.totalQuantity
      ? data.selection?.totals?.totalQuantity - giftboxs.length
      : 0,
  };

  const formattedData = {
    ...data,
    selection: {
      ...data.selection,
      items: productsGroupedByGiftbox,
      products,
      summary,
    },
    // Keep original selection for optimistic update changes
    rawSelection: data.selection,
  };

  return formattedData;
};
export type FormattedSelection = ReturnType<typeof formatSelection>;

export default formatSelection;

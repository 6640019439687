import { CentraSelectionItem } from "../../../schemas/selection/selectionProductSchema";
import { CentraSelection } from "../../../schemas/selection/selectionSchema";
import {
  CentraProductAllPricesBase,
  CentraProductSinglePricesBase,
} from "../../../types/product";
import {
  buildGetProductBreadcrumbs,
  getCanonicalCategory,
  getCountryOfOrigin,
  getProductSizes,
  getSizeRange,
} from "./baseAtomics";
import { getDisplayPrice, getDisplayPricesWithSale } from "./priceAtomics";

export const atomicGetField = <
  T extends CentraProductAllPricesBase | CentraProductSinglePricesBase,
  K extends keyof Required<T> = keyof Required<T>,
>(
  field: K,
  product: T,
): Required<T>[K] => {
  return product[field];
};

export const buildBaseAtoms = <
  ProductServer extends CentraProductAllPricesBase,
  ProductClient extends CentraProductSinglePricesBase,
  T extends CentraProductAllPricesBase | CentraProductSinglePricesBase =
    | ProductServer
    | ProductClient,
>({
  breadcrumbPrefix,
}: {
  breadcrumbPrefix?: string | undefined;
}) => {
  const atoms = {
    getName: (product: T) => atomicGetField("name", product),
    getProduct: (product: T) => atomicGetField("product", product),
    getProductSku: (product: T) => atomicGetField("productSku", product),
    getSku: (product: T) => atomicGetField("sku", product),
    getVariantName: (product: T) => atomicGetField("variantName", product),
    getUri: (product: T) => atomicGetField("uri", product),
    getCategory: (product: T) => atomicGetField("category", product),
    getCategoryName: (product: T) => atomicGetField("categoryName", product),
    getCategoryUri: (product: T) => atomicGetField("categoryUri", product),
    getCategories: (product: T) => atomicGetField("categories", product),
    getCountryOrigin: (product: T) => atomicGetField("countryOrigin", product),
    getTableMappings: (product: T) => atomicGetField("tableMappings", product),
    getDescription: (product: T) => atomicGetField("description", product),
    getDescriptionHtml: (product: T) =>
      atomicGetField("descriptionHtml", product),
    getMetaTitle: (product: T) => atomicGetField("metaTitle", product),
    getMetaDescription: (product: T) =>
      atomicGetField("metaDescription", product),
    getMetaKeywords: (product: T) => atomicGetField("metaKeywords", product),
    getCollection: (product: T) => atomicGetField("collection", product),
    getCollectionName: (product: T) =>
      atomicGetField("collectionName", product),
    getCollectionUri: (product: T) => atomicGetField("collectionUri", product),
    getBrand: (product: T) => atomicGetField("brand", product),
    getBrandName: (product: T) => atomicGetField("brandName", product),
    getBrandUri: (product: T) => atomicGetField("brandUri", product),
    getMeasurementChart: (product: T) =>
      atomicGetField("measurementChart", product),
    getExcerpt: (product: T) => atomicGetField("excerpt", product),
    getExcerptHtml: (product: T) => atomicGetField("excerptHtml", product),
    getStockUnit: (product: T) => atomicGetField("stockUnit", product),
    getCentraProduct: (product: T) => atomicGetField("centraProduct", product),
    getCentraVariant: (product: T) => atomicGetField("centraVariant", product),
    getItemQuantityMinimum: (product: T) =>
      atomicGetField("itemQuantityMinimum", product),
    getItemQuantityMultipleOf: (product: T) =>
      atomicGetField("itemQuantityMultipleOf", product),
    getItemcreatedAt: (product: T) => atomicGetField("createdAt", product),
    getItemTable: (product: T) => atomicGetField("itemTable", product),
    getModifiedAt: (product: T) => atomicGetField("modifiedAt", product),
    getProductBreadcrumbs: buildGetProductBreadcrumbs(breadcrumbPrefix)<T>,
    getCanonicalCategory: getCanonicalCategory<T>,
    getCountryOfOrigin: getCountryOfOrigin<T>,
    getProductSizes: getProductSizes<T>,
    getSizeRange: getSizeRange<T>,
    getDisplayPriceWithSale: getDisplayPricesWithSale<
      ProductServer,
      ProductClient
    >,
    getDisplayPrice: getDisplayPrice<ProductServer, ProductClient>,
  };

  return atoms;
};

export const buildServerAtoms = <T extends CentraProductAllPricesBase>() => {
  const atoms = {
    getPrices: (product: T) => atomicGetField("prices", product),
    getItemsServer: (product: T) => atomicGetField("items", product),
  };

  return atoms;
};

export const buildClientAtoms = <T extends CentraProductSinglePricesBase>() => {
  const atoms = {
    getPrice: (product: T) => atomicGetField("price", product),
    getPriceBeforeDiscount: (product: T) =>
      atomicGetField("priceBeforeDiscount", product),
    getPriceAsNumber: (product: T) => atomicGetField("priceAsNumber", product),
    getPriceBeforeDiscountAsNumber: (product: T) =>
      atomicGetField("priceBeforeDiscountAsNumber", product),
    getShowAsOnSale: (product: T) => atomicGetField("showAsOnSale", product),
    getShowAsNew: (product: T) => atomicGetField("showAsNew", product),
    getAvailable: (product: T) => atomicGetField("available", product),
    getDiscountPercent: (product: T) =>
      atomicGetField("discountPercent", product),
    getLowestPrice: (product: T) => atomicGetField("lowestPrice", product),
    getItemsClient: (product: T) => atomicGetField("items", product),
  };

  return atoms;
};

export const buildSelectionProductAtoms = <T extends CentraSelectionItem>() => {
  const atoms = {
    getSelectionItemName: (item: T) => item?.product?.name,
    getSelectionItemUri: (item: T) => item?.product?.uri,
    getSelectionItemVariantName: (item: T) => item?.product?.variantName,
    getSelectionItemSize: (item: T) => item?.size,
    getSelectionItemLine: (item: T) => item?.line,
    getSelectionItemPriceEach: (item: T) => item?.priceEach,
    getSelectionItemPriceEachBeforeDiscount: (item: T) =>
      item?.priceEachBeforeDiscount,
    getSelectionItemQuantity: (item: T) => item?.quantity,
    getSelectionItemPriceAsNumber: (item: T) => item?.priceEachAsNumber,
    getSelectionItemId: (item: T) => item?.item,
    getSelectionItemSku: (item: T) => item?.sku,
    getSelectionItemCategory: (item: T) => item?.product?.category,
    getSelectionItemCategoryName: (item: T) => item?.product?.categoryName,
    getSelectionProductId: (item: T) => item?.product?.product,
  };

  return atoms;
};

export const buildSelectionAtoms = <T extends CentraSelection>() => {
  const atoms = {
    getTotalPrice: (data: T) => data?.selection?.totals?.itemsTotalPrice,
    getTotalPriceAsNumber: (data: T) =>
      data?.selection?.totals?.itemsTotalPriceAsNumber,
    getShippingPrice: (data: T) => data?.selection?.totals?.shippingPrice,
    getShippingPriceAsNumber: (data: T) =>
      data?.selection?.totals?.shippingPriceAsNumber,
    getGrandTotalPrice: (data: T) => data?.selection?.totals?.grandTotalPrice,
    getGrandTotalPriceAsNumber: (data: T) =>
      data?.selection?.totals?.grandTotalPriceAsNumber,
    getTotalQuantity: (data: T) => data?.selection?.totals?.totalQuantity,
    getGrandTotalPriceTax: (data: T) =>
      data?.selection?.totals?.grandTotalPriceTax,
    getGrandTotalPriceTaxAsNumber: (data: T) =>
      data?.selection?.totals?.grandTotalPriceTaxAsNumber,
    getPrices: (data: T) => {
      const prices = {
        total: atoms.getTotalPrice(data),
        shipping: atoms.getShippingPrice(data),
        shippingAsNumber: atoms.getShippingPriceAsNumber(data),
        totalAsNumber: atoms.getTotalPriceAsNumber(data),
        grandTotal: atoms.getGrandTotalPrice(data),
        grandTotalAsNumber: atoms.getGrandTotalPriceAsNumber(data),
        grandTotalPriceTax: atoms.getGrandTotalPriceTax(data),
        grandTotalPriceTaxAsNumber: atoms.getGrandTotalPriceTaxAsNumber(data),
      };
      return prices;
    },
    getDiscounts: (data: T) => data?.selection?.discounts?.vouchers,
    getDiscountAmount: (data: T) =>
      atoms.getDiscounts(data)?.[0]?.priceOff || "0",
    getFirstDiscountCode: (data: T) =>
      atoms.getDiscounts(data)?.[0]?.voucher || "",
    getAnyDiscount: (data: T) => {
      const discounts = atoms.getDiscounts(data);
      return discounts && discounts.length > 0;
    },
    getDiscount: (data: T) => {
      const discount = {
        code: atoms.getFirstDiscountCode(data),
        amount: atoms.getDiscountAmount(data),
        anyDiscount: atoms.getAnyDiscount(data),
      };
      return discount;
    },
    generateCartSummary: (data: T) => {
      const prices = atoms.getPrices(data);

      const totalQuantity = atoms.getTotalQuantity(data);
      const discount = atoms.getDiscounts(data);

      return {
        prices,

        totalQuantity,
        discount,
      };
    },
  };

  return atoms;
};

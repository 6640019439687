import { ZodType } from "zod";
import { CentraSelection } from "../../schemas/selection/selectionSchema";

const changePassword = async <
  TSchema extends ZodType<any, any, any>,
  FormattedSelection
>(
  variables: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  },
  customerToken: string,
  selectionSchema: TSchema,
  api: string,
  formatter: (arg0: CentraSelection) => FormattedSelection
) => {
  const { oldPassword, newPassword, confirmPassword } = variables;

  if (newPassword !== confirmPassword) throw Error("Passwords do not match");

  const res = await fetch(
    `${api}/password`,

    {
      headers: { "API-token": customerToken || "" },
      method: "PUT",
      body: JSON.stringify({
        password: oldPassword,
        newPassword
      })
    }
  );

  if (!res.ok) throw Error("Error logging in");
  const response = await res.json();
  return formatter(response);
};

export default changePassword;

import {
  CentraProductAllPricesBase,
  CentraProductSinglePricesBase,
  ProductPricesWithDiscount
} from "../../../types/product";
import { isFullCentraProduct } from "../../utilities";

const calculateDiscountPercentage = (
  original: number,
  current: number
): number => {
  const difference = original - current;
  const proportion = difference / original;
  const percentage = Math.round(proportion * 100);

  return percentage;
};

// TODO: Update this in line with centra
export const getDisplayPricesWithSale = <
  All extends CentraProductAllPricesBase,
  Single extends CentraProductSinglePricesBase
>(
  product: All | Single
): ProductPricesWithDiscount[] => {
  if (isFullCentraProduct(product)) {
    if (!product?.prices) {
      return [];
    }

    const pricelists: ProductPricesWithDiscount[] = Object.entries(
      product?.prices
    ).map((price) => {
      const currency = price?.[1].price
        ? price?.[1].price.match(/[a-zA-Z]+$/)?.[0]
        : undefined;

      return {
        pricelistId: price?.[0],
        price: price?.[1]?.price ?? "",
        priceAsNumber: price?.[1]?.priceAsNumber ?? 0,
        priceBeforeDiscount: price?.[1]?.priceBeforeDiscount ?? "",
        priceBeforeDiscountAsNumber:
          price?.[1]?.priceBeforeDiscountAsNumber ?? 0,
        discountPercent: calculateDiscountPercentage(
          price?.[1]?.priceBeforeDiscountAsNumber ?? 0,
          price?.[1]?.priceAsNumber ?? 0
        ),
        productOnSale: !!price?.[1]?.showAsOnSale,
        currency
      };
    });

    return pricelists;
  }

  const regex = /[A-Z]{3}/g; // match currency
  const priceText = product?.price || "";

  const price: ProductPricesWithDiscount = {
    pricelistId: undefined,
    price: product?.price ?? "",
    priceAsNumber: product?.priceAsNumber ?? 0,
    priceBeforeDiscount: product?.priceBeforeDiscount ?? "",
    priceBeforeDiscountAsNumber: product?.priceBeforeDiscountAsNumber ?? 0,
    discountPercent: calculateDiscountPercentage(
      product?.priceBeforeDiscountAsNumber ?? 0,
      product?.priceAsNumber ?? 0
    ),
    productOnSale: product?.showAsOnSale,
    currency: priceText.match(regex)?.join("") || ""
  };

  return [price];
};

export const getDisplayPrice = <
  All extends CentraProductAllPricesBase,
  Single extends CentraProductSinglePricesBase
>(
  product: All | Single
): { priceListId: string; price: string | null }[] | string | null => {
  if (isFullCentraProduct(product)) {
    if (!product?.prices) return "";
    const priceLists = Object.entries(product?.prices).map((price) => ({
      priceListId: price[0],
      price: price?.[1]?.price
    }));
    return priceLists;
  }
  return product?.price;
};
